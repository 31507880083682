<template>
  <input
    ref="inputRef"
    class="base-input number-input"
    type="number"
    :data-test-id="`${props.testId}__BaseInputNumberNullAllowed`"
    :value="internalValue"
    :step="step"
    :placeholder="placeholder"
    onkeydown="return event.keyCode !== 69"
    @input="inputHandler($event)"
  >
</template>

<script lang="ts" setup>
import { useDebounceFn, useEventListener } from '@vueuse/core'
import round from 'lodash/round'
import { ref, watch } from 'vue'

import { clamp } from '@/utils/common'

const props = defineProps({
  min: {
    type: Number,
    default: 0
  },
  max: {
    type: Number,
    default: 100
  },
  precision: {
    type: Number,
    default: 0
  },
  debounceAfter: {
    type: Number,
    default: 500
  },
  modelValue: {
    type: [Number, String],
    required: false
  },
  step: {
    type: Number,
    default: 1
  },
  placeholder: {
    type: String,
    required: false,
    default: ''
  },
  testId: {
    required: true,
    type: String
  }
})

const emit = defineEmits(['update:modelValue', 'inputStatus'])
const inputRef = ref<HTMLInputElement>()
const internalValue = ref(props.modelValue)
const lastValue = ref<string | number | undefined>(0)
const updateValue = useDebounceFn((event: Event) => {
  lastValue.value = internalValue.value
  const target = event.target as HTMLInputElement
  internalValue.value = target.value

  if (target.value !== '') {
    internalValue.value = clamp(round(Number(target.value), props.precision), props.min, props.max)
  }
  emit('update:modelValue', internalValue.value)
  emit('inputStatus', 'finished')
}, props.debounceAfter)

const inputHandler = (event: Event) => {
  emit('inputStatus', 'inprogress')
  void updateValue(event)
}

const handleScroll = () => {
  if (inputRef.value) {
    inputRef.value.blur()
  }
}

watch(
  () => props.modelValue,
  (value) => {
    if (value === '') {
      internalValue.value = undefined
      return
    }
    internalValue.value = value
  }
)

useEventListener(inputRef, 'wheel', handleScroll)
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type='number'] {
  appearance: textfield;
}
</style>
